import { BettorBasic, BettorPicksMetaData, BettorDisplay as CommonBettorDisplay } from "common";
import React from "react";
import BettorProfileCard from "./profile-card";
import Link from "next/link";
import Avatar from "../primitives/avatar";
import { createProfileSlug } from "@/utils/url";

export default function BettorDisplay({
  bettor,
  dark,
  noCard,
  noLink,
}: {
  bettor: BettorBasic & BettorPicksMetaData;
  dark?: boolean;
  noCard?: boolean;
  noLink?: boolean;
}) {
  if (noCard) {
    return <BettorDisplayInner bettor={bettor} dark={dark} noLink={noLink} />;
  } else {
    return (
      <></>)
    //   <BettorProfileCard bettor={bettor}>
    //     <BettorDisplayInner bettor={bettor} dark={dark} noLink={noLink} />
    //   </BettorProfileCard>
    // );
  }
}

function BettorDisplayInner({
  bettor,
  dark,
  noLink,
}: {
  bettor: BettorBasic & BettorPicksMetaData;
  dark?: boolean;
  noLink?: boolean;
}) {
  const html = (
    <>
      <Avatar size="md" img={bettor.avatarUrl}></Avatar>

      <div className="flex flex-col gap-[2px]">
        <div
          className={`whitespace-nowrap items-center ${
            dark ? "text-white" : "text-secondary-300"
          }`}
        >
          {bettor.displayName}
        </div>
        {bettor.activePicks > 0 && (
          <div className="flex items-start gap-[2px] pl-[9px]">
            <svg
              width="7"
              height="6"
              viewBox="0 0 7 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 0.5V5.5H7"
                stroke="url(#paint0_linear_1217_135003)"
                strokeWidth="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1217_135003"
                  x1="4"
                  y1="0.5"
                  x2="4"
                  y2="5.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FF0016" />
                  <stop offset="1" stopColor="#FF9900" />
                </linearGradient>
              </defs>
            </svg>
            <div className="active-picks-text text-label-sm-bold whitespace-nowrap normal-case">
              {bettor.activePicks} Active{" "}
              {bettor.activePicks === 1 ? "Pick" : "Picks"}
            </div>
          </div>
        )}
      </div>
    </>
  );
  if (noLink) {
    return <div className="flex items-center gap-2 py-0">{html}</div>;
  } else {
    return (
      <Link
        href={createProfileSlug(bettor.displayName)}
        className="flex items-center gap-2 py-0"
        scroll={false}
      >
        {html}
      </Link>
    );
  }
}
