"use client";
import Button from "../primitives/button";
import Card from "../primitives/card";
import Icon from "../primitives/icon";
import { Key, Suspense } from "react";
import { LoadingCircle } from "../primitives/loading";
import { useSportsbooks } from "@/utils/providers/sportsbook";

export default function TopSportsbooks() {
  const {sportsbooks, topSportsbooks, isLoading} = useSportsbooks();
  if (isLoading) {
    return <>Loading...</>
  };
  return (
    <Card
      header={{
        title: (
          <>
            <Icon name="flip-book" gradient="cold"></Icon>
            <span>Top Sportsbooks</span>
          </>
        ),
        action: (
          <Button variant="card" scroll={true} href="/sportsbooks">
            View All
          </Button>
        ),
      }}
    >
      <Suspense
        fallback={
          <div className="h-[380px] flex justify-center items-center">
            <LoadingCircle />
          </div>
        }
      >
        <TopSportsbooksInner data={sportsbooks}/>
      </Suspense>
    </Card>
  );
}

function TopSportsbooksInner({data}: {data: any}) {
  return (
    <ul className="grid grid-cols-2 gap-4">
      {data.slice(0, 6).map((sportsbook: { attributes: { title: any; slug: any; logo: any; tagline: any; }; }, i: Key | null | undefined) => {
        const { title, slug, logo, tagline } = sportsbook.attributes;
        return (
          <li key={i}>
            <a
              href={`/sportsbooks/${slug}`}
              className="p-4 shadow-sportsbook-card rounded-xl bg-white flex items-center justify-center flex-col gap-4 min-h-[116px]"
            >
              <img
                src={
                  logo.data
                    ? logo.data.attributes.url
                    : "/images/sportsbooks/betmgm.png"
                }
                alt={title}
                className="w-full max-w-[130px] max-h-[40px] object-contain"
              />
              {tagline && (
                <div className="text-caption text-secondary-400">{tagline}</div>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
